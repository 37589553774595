import { createPaginationStore } from './pagination';
import type { SearchUser } from '@/interfaces/types';

// Create stores for different content types
export const searchPostsStore = createPaginationStore();
export const searchPhotosStore = createPaginationStore();
export const searchVideosStore = createPaginationStore();
export const searchStreamsStore = createPaginationStore();
export const searchUsersStore = createPaginationStore<SearchUser>();

// Derived store to get the current active store based on filter
export function getStoreForFilter(filter: string) {
    switch(filter) {
        case 'photos':
            return searchPhotosStore;
        case 'videos':
            return searchVideosStore;
        case 'live':
            return searchStreamsStore;
        default: // handles 'top', 'latest', and any other post-based filters
            return searchPostsStore;
    }
}

// Helper type for the pagination response from the backend
export interface SearchPaginationResponse {
    data: any[];
    next_page_url: string | null;
    prev_page_url: string | null;
    current_page: number;
    total: number;
    per_page: number;
    hasMore: boolean;
}

// Helper function to initialize the appropriate store based on filter
export function initializeSearchStore(
    filter: string,
    initialData: any[],
    paginationConfig: SearchPaginationResponse | null
) {
    const store = getStoreForFilter(filter);

    if (paginationConfig) {
        store.initialize(
            initialData,
            paginationConfig.next_page_url,
            paginationConfig.hasMore
        );
    } else {
        store.reset();
    }
}

// Helper function to reset all search stores
export function resetAllSearchStores() {
    searchPostsStore.reset();
    searchPhotosStore.reset();
    searchVideosStore.reset();
    searchStreamsStore.reset();
}
